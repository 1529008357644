<template>
  <BaseLayout>
    <div class="container">
      <div class="row py-5">
        <div class="col-12 text-center">
          Bem vindo, <b>{{ username }}</b>!<br />
          Recurso: <b>gmembers</b>: {{ hasResources('gmembers') }}<br />
          Recurso: <b>gmembers</b>: {{ getResources('gmembers') }}<br />

          <a href="javascript:void(0);" @click="navigate">Leads (mesma aba)</a><br />
          <a href="javascript:void(0);" @click="navigate(true)">Leads (nova aba)</a>
        </div>
        tags: {{tags.length}}
        <div class="col-12">
          <b-button variant="primary" v-b-modal.modalSelectBlog>Trocar blog</b-button>
          <router-link to="/broadcast">
            <b-button variant="primary">Broadcast</b-button>
          </router-link>
        </div>


        <BaseButton :title="blogs" @clicked="handler">
        <template v-slot:content>
          asdasdas
        </template>
        </BaseButton>

        <font-awesome-icon icon="times" />


      </div>
    </div>

      <!-- modals -->
    <BaseModalSelectBlog :blogs="blogs" @selected="selectBlog" />




  </BaseLayout>
</template>

<script>
import RedirectService from '@/services/resources/RedirectService';
import LeadService from '@/services/resources/LeadService';
import Modal from '@/components/home/Modal';
const service = RedirectService.build();
const serviceLead = LeadService.build();

export default {

  components:{
    Modal
  },

  data(){
    return{
      users:[]
    }
  },
  
  created(){
    this.getResources();
  },

  computed:{
    username(){
      return this.$store.getters.user ? this.$store.getters.user.display_name : '';
    },
    blogs(){
      return this.$store.getters.blogs;
    },
    tags(){
      return this.$store.getters.tags;
    }
  },

  methods:{
    hasResources(key){
      return this.$store.getters['hasResources'](key);
    },
    getResources(key){
      return this.$store.getters['getResources'](key);
    },
    selectBlog(blog){
      this.$store.dispatch('blogSelected', blog);
    },

    navigate(newTab = false){

      service
      .create({})
      .then(resp => {
        
        // let url = 'https://testespark.gpages.com.br/gadmin/?rdrk='+ resp.ck +'#/leads/lista';
        let url = 'https://'+resp.domain+'/gadmin/?rdrk='+ resp.ck+'#/leads/lista';

        if(newTab){
          window.open(url, '_blank');
        }else{
          window.location.href = url;
        }

      
      })
    },
    loadLead(){

      let data = {
        id: 1,
        asdas: '123'
      };


      serviceLead
      .update(data)
      .then(resp => {
   
      }).catch(err => {

      });
    }
  },
  mounted(){
    this.loadLead()
  },
  
}
</script>
<style lang="scss" scoped>

</style>
